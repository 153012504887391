@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: 'Faktum Test', sans-serif;
  src: local("Faktum Test"), url("./assets/fonts/FAKTUM-LIGHT.OTF") format('woff');
}
body {
  margin: 0;
  font-family:'Faktum Test',sans-serif;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.text-w-shadow{
  text-shadow: 3px 3px #fff;
}
